import {
  Squares2X2Icon,
  BuildingStorefrontIcon,
  UsersIcon,
  ShoppingCartIcon,
  Cog8ToothIcon,
  BuildingOfficeIcon,
  TruckIcon,
  ClockIcon,
  MapIcon,
} from "@heroicons/vue/24/outline";

const navigation = [
  { name: "Dashboard", href: "/", icon: Squares2X2Icon },
  { name: "Pharmacies", href: "/pharmacies", icon: BuildingStorefrontIcon },
  { name: "Clinics", href: "/clinics", icon: BuildingOfficeIcon },
  { name: "Orders", href: "/orders", icon: ShoppingCartIcon },
  {
    name: "Delivery Schedules",
    href: "/delivery-schedules",
    icon: ClockIcon,
  },
  { name: "Order Map", href: "/order-map", icon: MapIcon },
  { name: "Couriers", href: "/drivers", icon: TruckIcon },
  { name: "Customers", href: "/customers", icon: UsersIcon },
  { name: "Account Settings", href: "/settings", icon: Cog8ToothIcon },
  // { name: 'Questionnaires', href: '/questionnaires', icon: ClipboardDocumentListIcon }
];

const toolsNavigation = [
  { name: "Manual Payment", href: "/manual-payment", initial: "MP" },
  { name: "EPOS Export", href: "/epos-export", initial: "EE" },
  { name: "Reporting", href: "/reporting", initial: "RE" },
  { name: "Messaging", href: "/messaging", initial: "ME" },
  // { name: "Tags", href: "/tags", initial: "Tg" },
];

export { navigation, toolsNavigation };
